const middleware = {}

middleware['check-auth'] = require('../middleware/check-auth.js')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['cookies'] = require('../middleware/cookies.js')
middleware['cookies'] = middleware['cookies'].default || middleware['cookies']

middleware['i18n'] = require('../middleware/i18n.js')
middleware['i18n'] = middleware['i18n'].default || middleware['i18n']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['sentry'] = require('../middleware/sentry.js')
middleware['sentry'] = middleware['sentry'].default || middleware['sentry']

middleware['user-agent'] = require('../middleware/user-agent.js')
middleware['user-agent'] = middleware['user-agent'].default || middleware['user-agent']

export default middleware
