import styled from 'vue-styled-components'
import colors from '@/components/kit/theme'

// use <color :background="'white'" :background-opacity="'30'" :border="'black'" :text="'black'"></color>

const componentProps = {
  background: String,
  backgroundOpacity: Number,
  border: String,
  borderRadius: String,
  text: String
}

const ColorDiv = styled('div', componentProps)`
  background-color: ${(props) =>
    props.backgroundOpacity == null
      ? colors.knownColor(props.background)
      : colors.knownColorWithOpacity(
          props.background,
          props.backgroundOpacity
        )};
  border: ${(props) => (props.border == null ? '' : '1px solid')}
    ${(props) => (props.border == null ? '' : colors.knownColor(props.border))};
  border-color: ${(props) => colors.knownColor(props.border)};
  color: ${(props) => colors.knownColor(props.text)};
  border-radius: ${(props) => props.borderRadius};

  .link:hover {
    background-color: ${(props) =>
      props.backgroundOpacity == null
        ? colors.knownColor(props.background)
        : colors.knownColorWithOpacity(
            props.background,
            props.backgroundOpacity
          )};
  }
`

export default {
  props: {
    background: String,
    backgroundOpacity: Number,
    border: String,
    borderRadius: String,
    text: String
  },
  render(h) {
    return (
      <ColorDiv
        class="color"
        background={this.background}
        background-opacity={this.backgroundOpacity}
        border={this.border}
        border-radius={this.borderRadius}
        text={this.text}
      >
        {this.$slots.default}
      </ColorDiv>
    )
  }
}
