export default function({ store }) {
  if (!store.getters['cookies/isAuthenticated']) {
    let cookies

    if (!process.server) {
      cookies = window.localStorage.getItem('cookies')
    }

    store.commit('cookies/setCookies', { cookies })
  }
}
