import Vue from 'vue'

const iconsAsset = [require.context('~/assets/icons/', true, /\.(svg)$/i)]

const createComponentFromIcon = (name, definition) => {
  if (
    typeof definition === 'string' &&
    definition.startsWith('data:image/svg+xml')
  ) {
    // console.log('falling back to data url for svg ' + name)
    return Vue.component(name, {
      data() {
        return {
          imageUrl: definition
        }
      },
      template: '<img :src="imageUrl"/>'
    })
  }
  if (!definition.default) {
    // console.error('invalid icon definition: ', definition, name)
  }
  return Vue.component(name, definition)
}

iconsAsset.forEach((req) => {
  req.keys().map((key) => {
    const folderNames = key.match(/\w+[-\w+]*/g)
    if (folderNames.length < 2) {
      // icon filename + svg extension = 2 elements at minimum
      // console.error('Invalid icons folder structure detected. No icons found')
      return key
    }
    // remove the extension, if present
    const svgExtensionIx = folderNames.indexOf('svg')
    if (svgExtensionIx > -1) {
      folderNames.splice(svgExtensionIx, 1)
    }

    if (folderNames[0] === 'simple') {
      // expected structure: ./simple/{size}/{name}.svg
      // component name: ico-{name}-{size}
      const componentName = `ico-${folderNames[2]}-${folderNames[1]}`
      return createComponentFromIcon(componentName, req(key))
    }

    if (folderNames[0] === 'custom') {
      if (folderNames.length === 2) {
        // expected structure: ./custom/{name}.svg
        // component name: ico-custom-{name}
        const componentName = `ico-multi-${folderNames[1]}`
        return createComponentFromIcon(componentName, req(key))
      }
      // expected structure: ./multi/{size}/{name}.svg
      // component name: ico-multi-{name}-{size}
      const componentName = `ico-custom-${folderNames[2]}-${folderNames[1]}`
      return createComponentFromIcon(componentName, req(key))
    }

    if (folderNames[0] !== 'simple' && folderNames[0] !== 'custom') {
      // expected structure: ./{size}/{name}.svg
      // component name: ico-{name}-{size}
      const componentName = `ico-${folderNames[1]}-${folderNames[0]}`
      return createComponentFromIcon(componentName, req(key))
    }

    /* console.error(
      `Invalid icons folder structure detected at ${key}. Attempting to use it "as is"...`
    ) */
    return createComponentFromIcon(folderNames[0], req(key))
  })
})
