import styled from 'vue-styled-components'
import colors from '~/components/kit/theme'

const componentProps = {
  fill: String,
  size: String,
  width: {
    type: String,
    default: '24px'
  },
  height: {
    type: String,
    default: '24px'
  },
  hover: {
    type: String,
    default: ''
  }
}

const StyledSvg = styled('span', componentProps)`
  &.ico {
    width: ${(props) => (props.size ? props.size : props.width)};
    height: ${(props) => (props.size ? props.size : props.height)};
    fill: ${(props) => (props.fill ? colors[props.fill] : colors['gray-mid'])};

    &:hover:not(.disabled),
    &.active:not(.disabled) {
      fill: ${(props) => colors[props.hover]};

      use,
      use + *,
      * {
        fill: ${(props) => colors[props.hover]};
      }
    }

    use,
    use + *,
    * {
      fill: ${(props) =>
        props.fill ? colors[props.fill] : colors['gray-mid']};
    }
  }
`

export default StyledSvg
