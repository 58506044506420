export const state = () => ({
  isLoading: true,
  keepIsLoading: false,
  combinationSelectorIsLoading: true,
  isLoadingTranslations: true,
  availableModels: [],
  availableFilteredModels: [],
  hasTankModel: false,
  tankModels: [],
  filteredTankModels: [],
  selectedModelType: null,
  selectedImage: null,
  selectedModelImages: [],
  selectedModel360Images: [],
  selectedModel360ImagesUrl: [],
  model360ImagesProp: '',
  modelImagesProp: '',
  outdoorType: false,
  indoorType: false,
  tankOptions: false,
  indoorTypeName: 'wall-mounted',
  modelTypeKeyword: '',
  modelTypeKeywords: null,
  indoorTypes: [],
  overview: [],
  specTable: [],
  settings: false,
  wiring: [],
  soundPressure: [],
  dimensions: null,
  options: null,
  selectedOption: null,
  operationLimitsHC: [],
  operationLimitsDHW: [],
  operationLimits: [],
  espCurve: [],
  productGroups: [],
  selectedProductGroup: null,
  currentCdsLink: null,
  stickyNavItems: [
    { name: 'availablemodels', active: false, activating: false }
  ],
  selectedOutdoorUnit: null,
  selectedIndoorUnit: null,
  selectedTankUnit: null,
  combinationSelection: null,
  subject: null,
  stickyNavigation: false,
  isPreviewTesting: false,
  availableModelsProductGroup: [],
  technicalOptions: []
})

export const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value
  },
  setKeepIsLoading(state, value) {
    state.keepIsLoading = value
  },
  setIsPreviewTesting(state, value) {
    state.isPreviewTesting = value
  },
  setCombinationSelectorIsLoading(state, value) {
    state.combinationSelectorIsLoading = value
  },
  setIsLoadingTranslations(state, value) {
    state.isLoadingTranslations = value
  },
  setCombinationSelection(state, { bool, type }) {
    if (bool) {
      state.combinationSelection = type
    } else {
      state.combinationSelection = null
    }
  },
  setStickyNavigation(state, value) {
    state.stickyNavigation = value
  },
  setSelectedTankUnit(state, value) {
    state.selectedTankUnit = value
  },
  setSelectedOutdoorUnitImage(state, value) {
    state.selectedOutdoorUnitImage = value
  },
  setSelectedOutdoorUnit(state, value) {
    state.selectedOutdoorUnit = value
  },
  setSelectedIndoorUnit(state, value) {
    state.selectedIndoorUnit = value
  },
  setCurrentCdsLink(state, value) {
    state.currentCdsLink = value
  },
  setHasTankModel(state, value) {
    state.hasTankModel = value
  },
  setProductGroups(state, value) {
    state.productGroups = value
  },
  setSelectedProductGroup(state, value) {
    state.selectedProductGroup = value
  },
  setModel360ImagesProp(state, value) {
    state.model360ImagesProp = value
  },
  setModelImagesProp(state, value) {
    state.modelImagesProp = value
  },
  setSelectedImage(state, value) {
    state.selectedImage = value
  },
  setAvailableModels(state, value) {
    state.availableModels = value
  },
  setAvailableFilteredModels(state, value) {
    state.availableFilteredModels = value
  },
  setTankModels(state, value) {
    state.tankModels = value
    if (state.tankModels.length > 0) {
      state.tankOptions = true
    } else {
      state.tankOptions = false
    }
  },
  setFilteredTankModels(state, value) {
    state.filteredTankModels = value
  },
  setOutdoorType(state, value) {
    state.outdoorType = value
  },
  setIndoorType(state, value) {
    state.indoorType = value
  },
  setTankOptions(state, value) {
    state.tankOptions = value
  },
  setSelectedModelImages(state, value) {
    const mySorter = function(a, b) {
      const x = a.directus_files_id.title.toLowerCase()
      const y = b.directus_files_id.title.toLowerCase()
      return x < y ? -1 : x > y ? 1 : 0
    }
    if (value) {
      state.selectedModelImages = value.sort(mySorter)
    }
  },
  setSelectedModel360Images(state, value) {
    const mySorter = function(a, b) {
      const x = a.directus_files_id.title.toLowerCase()
      const y = b.directus_files_id.title.toLowerCase()
      return x < y ? -1 : x > y ? 1 : 0
    }
    if (value) {
      const valueSorted = value.sort(mySorter)
      state.selectedModel360Images = valueSorted
      const images = valueSorted.map(function(image) {
        return image.directus_files_id.data.full_url
      })
      state.selectedModel360ImagesUrl = images
    }
  },
  setSelectedModelType(state, value) {
    state.selectedModelType = value
  },
  setIndoorTypeName(state, value) {
    state.indoorTypeName = value
  },
  setModelTypeKeyword(state, value) {
    state.modelTypeKeyword = value
  },
  setModelTypeKeywords(state, value) {
    state.modelTypeKeywords = value
  },
  setIndoorTypes(state, value) {
    state.indoorTypes = value
  },
  setActiveNavItem(state, value) {
    for (const o in state.stickyNavItems) {
      if (state.stickyNavItems[o] === value.item) {
        state.stickyNavItems[o].active = value.active
      }
    }
  },
  setActivatingNavItem(state, value) {
    for (const o in state.stickyNavItems) {
      if (state.stickyNavItems[o] === value.item) {
        state.stickyNavItems[o].activating = value.activating
      }
    }
  },
  setSelectedOption(state, value) {
    if (value) {
      state.selectedOption = state.options.find((item) => item.id === value)
    } else {
      state.selectedOption = null
    }
  },
  setOptions(state, value) {
    state.options = null
    state.options = value
  },
  setSubject(state, value) {
    state.modelTypeKeyword = value
  },
  setSettings(state, value) {
    if (value) {
      state.settings = true
      state.stickyNavItems = state.stickyNavItems.filter(
        (el) => el.name !== 'settings'
      )
      state.stickyNavItems.splice(1, 0, {
        name: 'settings',
        active: false,
        activating: false
      })
    } else {
      state.settings = false
      state.stickyNavItems = state.stickyNavItems.filter(
        (el) => el.name !== 'settings'
      )
    }
  },
  setOverview(state, { value, combination }) {
    state.specTable = []
    state.wiring = []
    state.soundPressure = []
    state.dimensions = null
    state.operationLimitsHC = []
    state.operationLimitsDHW = []
    state.operationLimits = []
    state.espCurve = []
    state.technicalOptions = []

    if (combination) {
      state.stickyNavItems = [
        { name: 'additionallinks', active: false, activating: false },
        { name: 'capacitytable', active: false, activating: false }
      ]
    } else {
      state.stickyNavItems = [
        { name: 'availablemodels', active: false, activating: false }
      ]
    }

    if (state.tankModels.length > 0 && !combination) {
      state.stickyNavItems.push({
        name: 'tankoptions',
        active: false,
        activating: false
      })
    }

    const overview = value
    // Remove any duplicates by checking cds_link
    const filteredData = [...overview].reduce((d, current) => {
      const x = d.find((item) => item.cds_link === current.cds_link)
      if (!x) {
        return d.concat([current])
      } else {
        return d
      }
    }, [])
    filteredData.filter(function(data) {
      try {
        const keyword = data.title_keyword.keyword
        if (keyword === 'spectable') {
          state.specTable.push(data)
        } else if (keyword === 'dimensions') {
          state.dimensions = data
        } else if (keyword === 'wiring') {
          state.wiring.push(data)
        } else if (keyword === 'soundpressure') {
          state.soundPressure.push(data)
        } else if (keyword === 'operationlimitshc') {
          if (state.outdoorType) {
            state.operationLimitsHC.push(data)
          }
        } else if (keyword === 'operationlimitsdhw') {
          if (state.outdoorType) {
            state.operationLimitsDHW.push(data)
          }
        } else if (keyword === 'operationlimits') {
          if (state.outdoorType) {
            state.operationLimits.push(data)
          }
        } else if (keyword === 'espcurve') {
          if (
            !(
              state.selectedProductGroup &&
              state.selectedProductGroup.slug === 'altherma-3-r' &&
              (state.indoorTypeName === 'floorstanding' ||
                state.indoorTypeName === 'wall-mounted')
            )
          ) {
            state.espCurve.push(data)
          }
        } else if (keyword === 'technicaloptions') {
          state.technicalOptions.push(data)
        }
      } catch (err) {
        throw err
      }
    })

    if (state.specTable.length > 0) {
      state.stickyNavItems.push({
        name: 'spectable',
        active: false,
        activating: false
      })
    }
    if (state.dimensions) {
      state.stickyNavItems.push({
        name: 'dimensions',
        active: false,
        activating: false
      })
    }
    if (state.wiring.length > 0) {
      state.stickyNavItems.push({
        name: 'wiring',
        active: false,
        activating: false
      })
    }
    if (state.soundPressure.length > 0) {
      state.stickyNavItems.push({
        name: 'soundpressure',
        active: false,
        activating: false
      })
    }
    if (state.options && state.options.length && !combination) {
      state.stickyNavItems.push({
        name: 'options',
        active: false,
        activating: false
      })
    }
    if (
      state.operationLimits.length > 0 ||
      state.operationLimitsHC.length > 0 ||
      state.operationLimitsDHW.length > 0
    ) {
      state.stickyNavItems.push({
        name: 'operationlimits',
        active: false,
        activating: false
      })
    }
    if (state.espCurve.length > 0) {
      state.stickyNavItems.push({
        name: 'espcurve',
        active: false,
        activating: false
      })
    }
    if (state.technicalOptions.length > 0) {
      state.stickyNavItems.push({
        name: 'technicaloptions',
        active: false,
        activating: false
      })
    }

    state.overview = overview
  },
  setAvailableModelsProductGroup(state, value) {
    state.availableModelsProductGroup = value
  }
}

export const actions = {
  resetSelectedUnitsAndFilters({ commit }, productGroupSlug) {
    commit('filterwidget/resetFilters', productGroupSlug, { root: true })
    commit('setSelectedOutdoorUnit', null)
    commit('setSelectedIndoorUnit', null)
    commit('setSelectedTankUnit', null)
    commit('imagehighlights/setHighlights', null, { root: true })
  },
  async getProductGroup({ commit, dispatch, rootState }, query) {
    commit('setIsLoading', true)
    const filterPreview = '&filter[preview]'
    const previewTesting =
      query && query === 'testing'
        ? `${filterPreview}[in]=public,testing`
        : `${filterPreview}=public`
    const availableProductGroups = await this.$axios.$get(
      `_/items/product_group?fields=*.*.*,images.directus_files_id.data.full_url,images.directus_files_id.title${previewTesting}`
    )

    const locales = rootState.locales
    const currentLocale = locales.find(
      (item) => item.culture === rootState.locale
    )

    const models = await this.$axios.$get(
      `_/items/models?fields=*,active_cultures.*,product_group.*&filter[active_cultures.culture][eq]=${currentLocale?.id}&limit=-1`
    )

    const productGroupsToShow = models?.data?.map(
      (item) => item.product_group?.id
    )

    const filteredResponse = availableProductGroups?.data.filter((item) =>
      productGroupsToShow.includes(item.id)
    )

    await dispatch('resetSelectedUnitsAndFilters', null)
    commit('setProductGroups', filteredResponse)
    commit('setIsLoading', false)
  },
  async getProductGroupByParam({ state, commit, dispatch }, productGroupParam) {
    const response = await this.$axios.$get(
      `_/items/product_group?fields=*.*,images.directus_files_id.data.full_url,images.directus_files_id.title&filter[slug]=${productGroupParam}`
    )
    await dispatch('resetSelectedUnitsAndFilters', productGroupParam)
    commit('setSelectedProductGroup', response.data[0])
  },
  async getSubject(
    { state, rootState, commit, dispatch },
    { modelType, subjectId, productgroup }
  ) {
    commit('setIsLoading', true)
    if (!state.selectedProductGroup) {
      await dispatch('getProductGroupByParam', productgroup)
    }
    const typeName = modelType === 'outdoor' ? 'outdoor' : 'indoor'
    const typeFilter =
      modelType !== 'combination' ? `&filter[type.name]=${typeName}` : ''
    let indoorTypeFilter = ''
    if (typeFilter) {
      indoorTypeFilter =
        modelType === 'outdoor' ? '' : `&filter[indoor_type.name]=${modelType}`
    }
    const subject = await this.$axios.$get(
      `_/items/overview?fields=*.*${typeFilter}${indoorTypeFilter}&filter[language.culture]=${rootState.locale}&filter[id]=${subjectId}`
    )
    const keyword = typeFilter ? modelType : ''
    commit('setCurrentCdsLink', subject.data[0].cds_link)
    commit('setSubject', subject.data[0])
    commit('setModelTypeKeyword', keyword)
    commit('setIsLoading', false)
  },
  async getOverview({ state, rootState, commit }) {
    const typeName = state.outdoorType ? 'outdoor' : 'indoor'
    const modelType = state.outdoorType ? 'outdoor_model' : 'indoor_model'
    const indoorTypeFilter = state.outdoorType
      ? ''
      : `&filter[indoor_type.name]=${state.indoorTypeName}`
    const filter = state.outdoorType
      ? `&filter[outdoor_model.name]=`
      : `&filter[indoor_model.name]=`
    const selectedIndoorUnitFilter =
      state.selectedIndoorUnit && state.selectedIndoorUnit.name
        ? `${filter}${state.selectedIndoorUnit.name}`
        : `&filter[indoor_model.name][in]=${state.availableModels.map(
            (item) => item.name
          )}`
    const selectedOutdoorUnitFilter =
      state.selectedOutdoorUnit && state.selectedOutdoorUnit.name
        ? `${filter}${state.selectedOutdoorUnit.name}`
        : `&filter[outdoor_model.name][in]=${state.availableModels.map(
            (item) => item.name
          )}`
    const selectedUnitFilter = state.outdoorType
      ? selectedOutdoorUnitFilter
      : selectedIndoorUnitFilter
    const modelTypeFilter = state.outdoorType
      ? ',outdoor_model.*'
      : ',indoor_model.*'
    const productGroupFilter = `&filter[${modelType}.product_group.slug]=${state.selectedProductGroup.slug}`

    const overview = await this.$axios.$get(
      `_/items/overview?fields=*.*${modelTypeFilter}${productGroupFilter}&filter[language.culture]=${rootState.locale}&filter[type.name]=${typeName}${indoorTypeFilter}${selectedUnitFilter}`
    )

    commit('setOverview', { value: overview.data })
  },
  async getCombinationOverview({ state, rootState, commit, dispatch }, params) {
    commit('setIsLoading', true)
    if (!state.selectedProductGroup) {
      await dispatch('getProductGroupByParam', params.productgroup)
    }
    const indoorFilter = '&filter[indoor_model.name]='
    const outdoorFilter = '&filter[outdoor_model.name]='
    const selectedIndoorUnitFilter =
      state.selectedIndoorUnit && state.selectedIndoorUnit.name
        ? `${indoorFilter}${state.selectedIndoorUnit.name}`
        : ''
    const selectedOutdoorUnitFilter =
      state.selectedOutdoorUnit && state.selectedOutdoorUnit.name
        ? `${outdoorFilter}${state.selectedOutdoorUnit.name}`
        : ''
    const modelTypeFilter = ',indoor_model.*,outdoor_model.*'
    const productGroupFilter = `&filter[indoor_model.product_group.slug]=${state.selectedProductGroup.slug}&filter[outdoor_model.product_group.slug]=${state.selectedProductGroup.slug}`
    const overview = await this.$axios.$get(
      `_/items/overview?fields=*.*${modelTypeFilter}${productGroupFilter}&filter[language.culture]=${rootState.locale}&filter[type.name]=combination${selectedIndoorUnitFilter}${selectedOutdoorUnitFilter}`
    )

    commit('setOverview', { value: overview.data, combination: true })
    if (!state.keepIsLoading) {
      commit('setIsLoading', false)
    }
  },
  async getOptions({ commit, state, rootState }) {
    const modelIds = state.availableModels.map((item) => item.id)
    const currentLocaleObject = rootState.locales.find(
      (item) => item.culture === rootState.locale
    )
    const options = await this.$axios.$get(
      `_/items/options?fields=id,image_url,material_code,product_code,description.id,explanation.id&filter[active_models.model][in]=${modelIds}&filter[active_models.culture.id]=${currentLocaleObject.id}&filter[description.translation_values.language]=${currentLocaleObject.id}`
    )
    let arr = []
    arr = options?.data.map((option) => {
      return arr.concat([option.description.id, option.explanation.id])
    })
    const optionValues = await this.$axios.$get(
      `_/items/option_translation_values?fields=*&filter[translation_keyword][in]=${arr.join(
        ','
      )}&filter[language]=${currentLocaleObject.id}`
    )
    const mappedOptions = options.data
      .map((item) => {
        const explanationValues = optionValues?.data?.find(
          (optionValue) =>
            optionValue.translation_keyword === item.explanation.id
        )
        const descriptionValues = optionValues?.data?.find(
          (optionValue) =>
            optionValue.translation_keyword === item.description.id
        )
        return {
          ...item,
          explanation:
            explanationValues && explanationValues.translation
              ? explanationValues.translation
              : '-',
          description:
            descriptionValues && descriptionValues.translation
              ? descriptionValues.translation
              : '-'
        }
      })
      .sort((a, b) => {
        if (a.description < b.description) {
          return -1
        }
        if (a.description > b.description) {
          return 1
        }
        return 0
      })

    commit('setOptions', mappedOptions)
  },
  async getAvailableModels(
    { state, rootState, commit, dispatch },
    { typeName, indoorTypeFilter, isOutdoorType }
  ) {
    const modelType = isOutdoorType ? 'type' : 'indoor_type'
    const models = await this.$axios.$get(
      `_/items/models?fields=*.*,phase.*.*,certificates.*.*,active_cultures.culture.culture&filter[product_group.slug]=${state.selectedProductGroup.slug}&filter[type.name]=${typeName}${indoorTypeFilter}`
    )

    commit('setOutdoorType', isOutdoorType)
    await dispatch('checkCombinationModels')
    let filteredModels = models.data

    const mySorter = function(a, b) {
      const x = a.directus_files_id.title.toLowerCase()
      const y = b.directus_files_id.title.toLowerCase()
      return x < y ? -1 : x > y ? 1 : 0
    }
    models.data.forEach((filteredModel) => {
      if (filteredModel[modelType][state.modelImagesProp]) {
        filteredModel[modelType][state.modelImagesProp].sort(mySorter)
      }
    })
    const currentLocale = rootState.locale

    filteredModels = filteredModels.filter((model) => {
      const activeCultures = model.active_cultures
        ? model.active_cultures.map((item) => item.culture?.culture)
        : []
      return activeCultures.includes(currentLocale)
    })

    const combinationModels = rootState.combinations.combinationModels
    if (
      (!state.outdoorType && state.selectedOutdoorUnit) ||
      (state.outdoorType && state.selectedIndoorUnit)
    ) {
      filteredModels = filteredModels.filter((m) =>
        combinationModels.includes(m.id)
      )
    }
    commit('setAvailableModels', filteredModels)
  },
  async checkCombinationModels({ state, commit, dispatch }) {
    if (state.selectedOutdoorUnit || state.selectedIndoorUnit) {
      let data = null
      if (state.outdoorType && state.selectedIndoorUnit) {
        data = {}
        data.modelType = 'indoor'
        data.combinationType = 'outdoor'
        data.modelId = state.selectedIndoorUnit.id
      }
      if (!state.outdoorType && state.selectedOutdoorUnit) {
        data = {}
        data.modelType = 'outdoor'
        data.combinationType = 'indoor'
        data.modelId = state.selectedOutdoorUnit.id
      }
      if (data) {
        await dispatch('combinations/getCombinations', data, { root: true })
      } else {
        commit('combinations/setCombinationModels', [], { root: true })
        commit('combinations/setHasCombination', false, { root: true })
      }
    } else {
      commit('combinations/setCombinationModels', [], { root: true })
      commit('combinations/setHasCombination', false, { root: true })
    }
  },
  async getTankModels(
    { state, dispatch, commit, rootState },
    { productgroup, loading }
  ) {
    if (loading) {
      commit('setCombinationSelectorIsLoading', true)
    }

    if (productgroup && !state.selectedProductGroup) {
      await dispatch('getProductGroupByParam', productgroup)
    }

    const indoorType = state.indoorType
      ? state.indoorTypeName
      : state.selectedIndoorUnit
      ? state.selectedIndoorUnit.indoor_type.name
      : null
    let indoorTypeFilter = indoorType
      ? `&filter[indoor_type.name]=${indoorType}`
      : `&filter[indoor_type][null]`

    let flag = false
    if (state.selectedProductGroup && state.selectedIndoorUnit) {
      for (const key in state.selectedProductGroup.model_types) {
        if (
          state.selectedProductGroup.model_types[key] ===
          state.selectedIndoorUnit.indoor_type.name
        ) {
          flag = true
        }
      }
    }
    if (!flag && state.selectedIndoorUnit) {
      indoorTypeFilter = ''
    }
    const models = await this.$axios.$get(
      // Better filtering to reduce load time
      `_/items/models?fields=id,name,type.*,active_cultures.culture.culture,product_group.slug,image.title,image.title,image.id,image.data.full_url,solar_compatible,capacity&filter[product_group.slug]=${state.selectedProductGroup.slug}&filter[type.name]=tank${indoorTypeFilter}`
    )

    let filteredModels = models.data

    const currentLocale = rootState.locale

    filteredModels = filteredModels.filter((model) => {
      const activeCultures = model.active_cultures
        ? model.active_cultures.map((item) => item.culture?.culture)
        : []
      return activeCultures.includes(currentLocale)
    })

    if (filteredModels.length > 0) {
      commit('setTankModels', filteredModels)
      commit('setHasTankModel', true)
    } else {
      commit('setTankModels', [])
      commit('setHasTankModel', false)
    }
    dispatch('filterwidget/filterTanks', null, { root: true })

    if (loading) {
      commit('setCombinationSelectorIsLoading', false)
    }
  },
  async checkModelTypeKeywords({ state, commit }, modelType) {
    const indoorModelType =
      modelType === 'outdoor' ? modelType.toLowerCase() : `indoor_${modelType}`
    const modelTypeKeywords = await this.$axios.$get(
      `_/items/product_group_keywords?fields=info.keyword,subtitle.keyword,title.keyword,product_group.slug,model_type.name&filter[product_group.slug]=${state.selectedProductGroup.slug}&filter[model_type.name]=${indoorModelType}`
    )
    const modelTypeKeywordsData = modelTypeKeywords?.data[0]
    if (modelTypeKeywordsData) {
      const titleKeyword = modelTypeKeywordsData.title?.keyword
      const title = state.selectedProductGroup[titleKeyword]
      const subtitleKeyword = modelTypeKeywordsData.subtitle?.keyword
      const subtitle = state.selectedProductGroup[subtitleKeyword]
      const infoKeyword = modelTypeKeywordsData.info?.keyword
      const info = state.selectedProductGroup[infoKeyword]
      const keywords = {
        title: title ? title.keyword : titleKeyword,
        subtitle: subtitle ? subtitle.keyword : subtitleKeyword,
        info: info ? info.keyword : infoKeyword
      }
      commit('setModelTypeKeywords', keywords)
    }
  },
  async getIndoorTypes({ state, commit, dispatch }, modelType) {
    commit('setIndoorType', true)

    if (!state.availableModelsProductGroup?.length) {
      await dispatch(
        'getAvailableModelsForProductGroup',
        state.selectedProductGroup.slug
      )
    }

    const types = state.availableModelsProductGroup
      .map((item) => item.indoor_type?.name)
      .filter((item) => item)

    await dispatch('getModelImagesProp')
    const indoorTypes = await this.$axios.$get(
      `_/items/indoor_type?fields=*.*&filter[name][in]=${types}`
    )

    const mySorter = function(a, b) {
      const x = a.directus_files_id.title.toLowerCase()
      const y = b.directus_files_id.title.toLowerCase()
      return x < y ? -1 : x > y ? 1 : 0
    }

    indoorTypes.data.forEach((indoorType) => {
      if (indoorType[state.modelImagesProp]) {
        indoorType[state.modelImagesProp].sort(mySorter)
      }
    })
    commit('setIndoorTypes', indoorTypes.data)

    await indoorTypes.data.forEach(async (indoorType) => {
      const type =
        indoorType.name === 'wall-mounted' ? 'wallmounted' : indoorType.name
      if (modelType === indoorType.name) {
        commit('setSelectedModelType', indoorType)

        const imageResponse = await this.$axios.$get(
          `_/items/type_images?fields=*.*.*&filter[model_type.name]=indoor_${type}&filter[product_group.slug]=${state.selectedProductGroup.slug}`
        )
        const images360 = imageResponse.data[0][state.model360ImagesProp]
        const images = imageResponse.data[0][state.modelImagesProp]
        commit('setSelectedModelImages', images)
        commit(
          'setSelectedModel360Images',
          images360 && images360.length > 0 ? images360 : []
        )
        commit(
          'setSelectedImage',
          images360 && images360.length > 0
            ? images360[0]
            : images && images.length > 0
            ? images[0]
            : null
        )
        commit('setIndoorTypeName', indoorType.name)
        commit('setModelTypeKeyword', indoorType.keyword.keyword)
        await dispatch('checkModelTypeKeywords', indoorType.keyword.keyword)
        commit(
          'filterwidget/setSelectedIndoorType',
          {
            id: indoorType.name,
            i18n: indoorType.name,
            disabled: false
          },
          { root: true }
        )
      }
      const allImages = await this.$axios.$get(
        `_/items/type_images?fields=images.*.*&filter[model_type.name]=indoor_${type}&filter[product_group.slug]=${state.selectedProductGroup.slug}`
      )
      const sortedImages = allImages.data[0].images
      sortedImages.sort(mySorter)
      indoorType.images = sortedImages
    })
    commit('setIndoorTypes', indoorTypes.data)
  },
  getModelImagesProp({ state, commit }) {
    const modelTypeImagesProp = 'images'
    const modelType360ImagesProp = 'images360'

    commit('setModel360ImagesProp', modelType360ImagesProp)
    commit('setModelImagesProp', modelTypeImagesProp)
  },
  async getModelType({ state, commit, dispatch }, modelType) {
    commit('setIndoorType', false)
    const response = await this.$axios.$get(
      `_/items/type?fields=*.*.*&filter[name]=${modelType}`
    )
    commit('setModelTypeKeyword', modelType)
    commit('setSelectedModelType', response.data[0])
    await dispatch('checkModelTypeKeywords', modelType)
    await dispatch('getModelImagesProp')

    const imageResponse = await this.$axios.$get(
      `_/items/type_images?fields=*.*.*&filter[model_type.name]=outdoor&filter[product_group.slug]=${state.selectedProductGroup.slug}`
    )
    const images360 = imageResponse?.data[0]?.images360
    const images = imageResponse?.data[0]?.images

    const mySorter = function(a, b) {
      const x = a.directus_files_id.title.toLowerCase()
      const y = b.directus_files_id.title.toLowerCase()
      return x < y ? -1 : x > y ? 1 : 0
    }
    const sortedImages = imageResponse?.data[0]?.images
    sortedImages.sort(mySorter)
    if (sortedImages) {
      commit('setSelectedOutdoorUnitImage', sortedImages[0])
    }

    commit('setSelectedModelImages', images)
    commit(
      'setSelectedModel360Images',
      images360 && images360.length > 0 ? images360 : []
    )
    commit(
      'setSelectedImage',
      images360 && images360.length > 0
        ? images360[0]
        : images && images.length > 0
        ? images[0]
        : null
    )
  },
  async getUnits({ state, commit, dispatch }, params) {
    commit('setIsLoading', true)
    if (!state.selectedProductGroup) {
      await dispatch('getProductGroupByParam', params.productgroup)
    }
    const modelType = params.type
    const typeName = modelType === 'outdoor' ? 'outdoor' : 'indoor'
    const indoorTypeFilter =
      modelType === 'outdoor' ? '' : `&filter[indoor_type.name]=${modelType}`
    const isOutdoorType = modelType === 'outdoor'
    if (modelType === 'outdoor') {
      await dispatch('getModelType', modelType)
    } else {
      await dispatch('getIndoorTypes', modelType)
    }
    await dispatch('getAvailableModels', {
      typeName,
      indoorTypeFilter,
      isOutdoorType
    })
    await dispatch('getOptions')
    await dispatch('getTankModels', {
      productgroup: params.productgroup
    })
    await dispatch('getOverview')
    await dispatch(
      'imagehighlights/getHighlights',
      { typeName, indoorTypeFilter },
      { root: true }
    )
    commit('setIsLoading', false)
    commit('setKeepIsLoading', false)
  },
  async getUnitsCombinationPage({ commit, dispatch }, modelType) {
    commit('setIsLoading', true)
    const typeName = modelType === 'outdoor' ? 'outdoor' : 'indoor'
    const indoorTypeFilter =
      modelType === 'outdoor' ? '' : `&filter[indoor_type.name]=${modelType}`
    const isOutdoorType = modelType === 'outdoor'

    if (modelType === 'outdoor') {
      await dispatch('getModelType', modelType)
    } else {
      await dispatch('getIndoorTypes', modelType)
    }
    await dispatch('getAvailableModels', {
      typeName,
      indoorTypeFilter,
      isOutdoorType
    })
    commit('setIsLoading', false)
    commit('setKeepIsLoading', false)
  },
  async getAvailableModelsForProductGroup(
    { state, commit, dispatch, rootState },
    productGroup
  ) {
    if (!state.selectedProductGroup) {
      await dispatch('getProductGroupByParam', productGroup)
    }

    const locales = rootState.locales
    const currentLocale = locales.find(
      (item) => item.culture === rootState.locale
    )
    // new
    if (productGroup === undefined) {
      productGroup = state.selectedProductGroup.slug
    }

    const models = await this.$axios.$get(
      `_/items/models?fields=*,active_cultures.*,product_group.*,type.name,indoor_type.name&filter[active_cultures.culture][eq]=${currentLocale?.id}&filter[product_group.slug][eq]=${productGroup}&limit=-1`
    )

    commit('setAvailableModelsProductGroup', models?.data || [])
  }
}
