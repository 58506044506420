export default {
  /* BASIC COLORS FROM COLORS.LESS */
  'teal-midlight': '#8CEEDF',
  'teal-middark': '#5ac5b5',
  'teal-dark': '#1aa08c',
  'green-mid-light': '#92E6A8',
  'green-middark': '#67d580',
  'green-lemon': '#A5E04C',
  'green-lemonlight': '#B3E967',
  'gray-ultralight': '#fbfcfd',
  'gray-middark': '#32323f',
  'gray-dark': '#272731',
  'pink-light': '#ffb7c5',
  'pink-midlight': '#ff848f',
  'pink-mid': '#EF505E',
  'pink-dark': '#d43b59',
  'purple-light': '#b58fd7',
  'purple-midlight': '#A06DCE',
  'bluegray-light': '#bfc4dc',
  'bluegray-mid': '#49506f',
  'bluegray-dark': '#373d5a',
  'green-light': '#7bffc3',
  'green-mid': '#5cd399',
  'yellow-extralight': '#fff3d3',
  'yellow-light': '#ffe18f',
  'yellow-midlight': '#ffd38c',
  'yellow-mid-light': '#FFC45D',
  'yellow-dark': '#be7400',
  'orange-light': '#ffc47b',
  'orange-mid': '#FF955C',
  'orange-midlight': '#FFAA78',
  black: '#000000',
  white: '#ffffff',
  grey: '#303031',

  /* ADD PROJECT COLORS HERE */
  'blue-extralight': '#EDF9FF',
  'blue-light': '#AEDEF2',
  'blue-mid': '#5CBDE5',
  blue: '#00A0E2',
  'blue-dark': '#006096',
  green: '#35CDA2',
  'grey-light': '#D7E8F0',
  'grey-mid': '#C5D9E3',
  'grey-dark': '#4A627D',
  'grey-darker': '#304357',
  'red-light': '#ffdcdc',
  'red-mid': '#d13440',
  'elabel-aaaa': '#27A163',
  'elabel-aaa': '#36AB50',
  'elabel-aa': '#45B646',
  'elabel-a': '#6DB859',
  'elabel-b': '#8BC056',
  'elabel-c': '#B6CF4D',

  /* STATES */
  default: '#3D9AE3',
  warning: '#FFB61A',
  error: '#d13440',

  knownColor(value) {
    if (this[value]) {
      return this[value]
    }
    return value
  },
  convertHex(hex, opacity) {
    const hexValue = hex.replace('#', '')
    const r = parseInt(hexValue.substring(0, 2), 16)
    const g = parseInt(hexValue.substring(2, 4), 16)
    const b = parseInt(hexValue.substring(4, 6), 16)

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')'
  },
  knownColorWithOpacity(value, opacity) {
    if (this[value]) {
      const hexValue = this.colorcolor(this[value], 'hex')
      return this.convertHex(hexValue, opacity)
    }
    const hexRawValue = this.colorcolor(value, 'hex')
    return this.convertHex(hexRawValue, opacity)
  },
  colorcolor(color, newColor, calculateOpacity) {
    if (!newColor) {
      newColor = 'rgba'
    }
    if (!calculateOpacity) {
      calculateOpacity = false
    }
    color = color.toLowerCase()
    newColor = newColor.toLowerCase()
    let returnedColor = color
    let r, g, b, a
    const roundTo = 4
    const colorDefinitions = {
      hex: {
        re: /^#([0-9a-fA-F]{2})([0-9a-fA-F]{2})([0-9a-fA-F]{2})$/,
        example: ['00ff00', '336699'],
        toRGBA(bits) {
          return [
            parseInt(bits[1], 16),
            parseInt(bits[2], 16),
            parseInt(bits[3], 16),
            1
          ]
        }
      }
    }

    // Search the color definitions for a match
    for (const colorDefinition in colorDefinitions) {
      const re = colorDefinitions[colorDefinition].re
      const processor = colorDefinitions[colorDefinition].toRGBA
      const bits = re.exec(color)
      if (bits) {
        const channels = processor(bits)
        r = channels[0]
        g = channels[1]
        b = channels[2]
        a = +(Math.round(channels[3] + ('e+' + roundTo)) + ('e-' + roundTo))
      }
    }
    r = Math.round(r < 0 || isNaN(r) ? 0 : r > 255 ? 255 : r)
    g = Math.round(g < 0 || isNaN(g) ? 0 : g > 255 ? 255 : g)
    b = Math.round(b < 0 || isNaN(b) ? 0 : b > 255 ? 255 : b)
    a = a < 0 || isNaN(a) ? 0 : a > 1 ? 1 : a

    switch (newColor) {
      case 'hex':
        returnedColor =
          '#' +
          ('0' + r.toString(16)).slice(-2) +
          ('0' + g.toString(16)).slice(-2) +
          ('0' + b.toString(16)).slice(-2)
        break
      default:
        if (calculateOpacity) {
          ;[r, g, b, a] = this.calculateOpacityFromWhite(r, g, b, a)
        }
        returnedColor = `rgba(${r},${g},${b},${a})`
        break
    }

    return returnedColor
  },
  calculateOpacityFromWhite(r, g, b, a) {
    let min = 0
    a = (255 - (min = Math.min(r, g, b))) / 255
    r = (0 || (r - min) / a).toFixed(0)
    g = (0 || (g - min) / a).toFixed(0)
    b = (0 || (b - min) / a).toFixed(0)
    a = parseFloat(a.toFixed(4))

    return [r, g, b, a]
  },
  toPercent(amount, limit) {
    return amount / limit
  }
}
