export const state = () => ({
  hasDhwFunction: false,
  wiringDiagramUrl: null,
  pipingDiagramUrl: null
})

export const mutations = {
  setHasDhwFunction(state, value) {
    state.hasDhwFunction = value
  },
  setWiringDiagramUrl(state, value) {
    if (value && value.length > 0) {
      state.wiringDiagramUrl = value[0].file.data.full_url
    } else {
      state.wiringDiagramUrl = 'empty'
    }
  },
  setPipingDiagramUrl(state, value) {
    if (value && value.length > 0) {
      state.pipingDiagramUrl = value[0].file.data.full_url
    } else {
      state.pipingDiagramUrl = 'empty'
    }
  }
}

export const actions = {
  checkHasDhwFunction({ commit, rootState }) {
    if (
      rootState.models.selectedIndoorUnit &&
      rootState.models.selectedProductGroup.slug === 'altherma-3-h-ht'
    ) {
      if (
        rootState.models.selectedIndoorUnit.indoor_type.name ===
          'floorstanding' ||
        rootState.models.selectedIndoorUnit.indoor_type.name === 'ech2o' ||
        (rootState.models.selectedIndoorUnit.indoor_type.name ===
          'wall-mounted' &&
          rootState.models.selectedTankUnit)
      ) {
        commit('setHasDhwFunction', true)
      } else {
        commit('setHasDhwFunction', false)
      }
    } else {
      commit('setHasDhwFunction', false)
    }
  },
  checkProductGroupAvailability({ rootState }) {
    if (rootState.models.selectedProductGroup) {
      switch (rootState.models.selectedProductGroup.slug) {
        case 'altherma-3-r':
          if (
            rootState.models.selectedOutdoorUnit &&
            rootState.models.selectedIndoorUnit
          ) {
            return true
          }
          break
        case 'altherma-3-ewsa':
          if (rootState.models.selectedOutdoorUnit) {
            return true
          }
          break
        case 'altherma-3-m':
          if (rootState.models.selectedOutdoorUnit) {
            return true
          }
          break
        case 'altherma-3-m-ebla':
          if (rootState.models.selectedOutdoorUnit) {
            return true
          }
          break
        default:
          if (
            rootState.models.selectedOutdoorUnit &&
            rootState.models.selectedIndoorUnit
          ) {
            return true
          }
          break
      }
    }
    return false
  },
  async getWiringDiagram({ commit, rootState, dispatch }) {
    const continueFlag = await dispatch('checkProductGroupAvailability')
    if (!continueFlag) {
      commit('setWiringDiagramUrl', null)
      return
    }
    const indoorType = rootState.models.indoorType
      ? rootState.models.indoorTypeName
      : rootState.models.selectedIndoorUnit
      ? rootState.models.selectedIndoorUnit.indoor_type.name
      : null
    let indoorTypeFilter = indoorType
      ? `&filter[indoor_type.name]=${indoorType}`
      : `&filter[indoor_type][null]`
    let backupHeaterFilter = ''
    let flag = false
    if (
      rootState.models.selectedProductGroup &&
      rootState.models.selectedIndoorUnit
    ) {
      for (const key in rootState.models.selectedProductGroup.model_types) {
        if (
          rootState.models.selectedProductGroup.model_types[key] ===
          rootState.models.selectedIndoorUnit.indoor_type.name
        ) {
          flag = true
        }
      }
      if (!flag) {
        indoorTypeFilter = ''
      }
    }
    let phaseFilter = rootState.models.selectedOutdoorUnit?.phase?.value
      ? `&filter[phase.value]=${rootState.models.selectedOutdoorUnit.phase?.value}`
      : ''
    // Only for Altherma 3 R
    if (rootState.models.selectedProductGroup.slug === 'altherma-3-r') {
      phaseFilter = '&filter[phase][null]'
    }
    // Only for Altherma 3 M
    if (
      (rootState.models.selectedProductGroup.slug === 'altherma-3-m' &&
        rootState.models.selectedOutdoorUnit) ||
      (rootState.models.selectedProductGroup.slug === 'altherma-3-m-ebla' &&
        rootState.models.selectedOutdoorUnit)
    ) {
      const hasBackupHeater = rootState.models.selectedOutdoorUnit.backup_heater
        ? 'nempty'
        : 'empty'
      backupHeaterFilter = `&filter[backup_heater][${hasBackupHeater}]`
    }
    const modeFilter =
      rootState.models.selectedOutdoorUnit &&
      !rootState.models.selectedIndoorUnit
        ? `&filter[mode][contains]=${rootState.models.selectedOutdoorUnit.mode}`
        : ''
    let zoneFilter = ''
    if (
      rootState.models.selectedIndoorUnit &&
      rootState.models.selectedIndoorUnit.indoor_type.name === 'floorstanding'
    ) {
      zoneFilter = `&filter[zone]=${rootState.filterwidget.selectedZone.i18n}`
    }
    const diagram = await this.$axios.$get(
      `_/items/wiring?fields=*.*&filter[product_group.slug]=${rootState.models.selectedProductGroup.slug}${indoorTypeFilter}${phaseFilter}${modeFilter}${backupHeaterFilter}${zoneFilter}`
    )
    commit('setWiringDiagramUrl', diagram.data)
  },
  async getPipingDiagram({ commit, rootState, dispatch }, dhw) {
    const continueFlag = await dispatch('checkProductGroupAvailability')
    if (!continueFlag) {
      commit('setPipingDiagramUrl', null)
      return
    }
    const indoorType = rootState.models.indoorType
      ? rootState.models.indoorTypeName
      : rootState.models.selectedIndoorUnit
      ? rootState.models.selectedIndoorUnit.indoor_type.name
      : null
    let indoorTypeFilter = indoorType
      ? `&filter[indoor_type.name]=${indoorType}`
      : `&filter[indoor_type][null]`

    let flag = false
    if (
      rootState.models.selectedProductGroup &&
      rootState.models.selectedIndoorUnit
    ) {
      for (const key in rootState.models.selectedProductGroup.model_types) {
        if (
          rootState.models.selectedProductGroup.model_types[key] ===
          rootState.models.selectedIndoorUnit.indoor_type.name
        ) {
          flag = true
        }
      }
    }
    if (!flag && rootState.models.selectedIndoorUnit) {
      indoorTypeFilter = ''
    }
    let zoneFilter = ''
    if (
      rootState.models.selectedIndoorUnit &&
      rootState.models.selectedIndoorUnit.indoor_type.name === 'floorstanding'
    ) {
      zoneFilter = `&filter[zone]=${rootState.filterwidget.selectedZone.i18n}`
    }
    const dhwFilter = dhw === true ? `&filter[dhw]=dhw` : ''
    const emittersArr = []
    rootState.filterwidget.emitterList.find((emitter) => {
      if (emitter.checked) {
        emittersArr.push(emitter.name)
      }
    })
    if (emittersArr.length === 0) {
      emittersArr.push('empty')
    }
    const emitterFilter = `&filter[emitters]=${emittersArr.join(',')}`
    const diagram = await this.$axios.$get(
      `_/items/piping?fields=*.*&filter[product_group.slug]=${rootState.models.selectedProductGroup.slug}${zoneFilter}${dhwFilter}${emitterFilter}${indoorTypeFilter}`
    )
    commit('setPipingDiagramUrl', diagram.data)
  }
}
