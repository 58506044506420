import Vue from 'vue'

import CkIcon from '@/components/kit/CkIcon'
import ButtonColorDiv from '@/components/styledcomponents/ButtonColorDiv'
import AbsoluteDiv from '@/components/styledcomponents/AbsoluteDiv'
import ColorDiv from '@/components/styledcomponents/ColorDiv'
import ColorSpan from '@/components/styledcomponents/ColorSpan'
import ColorSection from '@/components/styledcomponents/ColorSection'
import FlexChildDiv from '@/components/styledcomponents/FlexChildDiv'
import FlexChildSpan from '@/components/styledcomponents/FlexChildSpan'
import FlexDiv from '@/components/styledcomponents/FlexDiv'
import PaddingDiv from '@/components/styledcomponents/PaddingDiv'

Vue.component('ck-icon', CkIcon)
Vue.component('absolute', AbsoluteDiv)
Vue.component('color', ColorDiv)
Vue.component('color-span', ColorSpan)
Vue.component('color-section', ColorSection)
Vue.component('button-color', ButtonColorDiv)
Vue.component('flex-child', FlexChildDiv)
Vue.component('flex-child-span', FlexChildSpan)
Vue.component('flex', FlexDiv)
Vue.component('padding', PaddingDiv)
