export const state = () => ({
  cookies: true
})

export const mutations = {
  setCookies(state, value) {
    state.cookies = value
  }
}

export const actions = {
  setCookies({ commit, rootState }, { cookies }) {
    if (!process.server) {
      window.localStorage.setItem('cookies', cookies)
    }
    commit('setCookies', cookies)
  },
  clearCookies({ commit, rootState }) {
    if (!process.server) {
      window.localStorage.removeItem('cookies')
    }
    commit('setCookies', { cookies: false })
  }
}

export const getters = {
  isAuthenticated(state) {
    return !!state.cookies
  }
}
