export default (ctx, inject) => {
  inject('convertDataToEnergyLabel', (type, data) => {
    return {
      type,
      elabel: data,
      text: ctx.app.i18n.t(`energylabel_${data}`)
    }
  })
  inject('sortByTitle', (a, b) => {
    if (a.title < b.title) {
      return -1
    } else if (a.title > b.title) {
      return 1
    } else {
      return 0
    }
  })
}
