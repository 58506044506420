import StyledSvg from './StyledSvg'
import StyledSvgCustom from './StyledSvgCustom'

// use <ck-icon :name="'ico-route-32px'" :fill="'@green-mid'" :hover="'@green-middark'" :size="'32px'" :width="'32px'" :height="'32px'" />

export default {
  props: {
    fill: String,
    size: String,
    width: String,
    hover: String,
    height: String,
    name: {
      type: String,
      default: 'ico-star-32px',
    },
  },
  render() {
    if (this.name.includes('custom')) {
      return (
        <StyledSvgCustom
          class="ico custom"
          size={this.size}
          width={this.width}
          height={this.height}
        >
          {this.$createElement(this.name)}
        </StyledSvgCustom>
      )
    } else {
      return (
        <StyledSvg
          class="ico"
          fill={this.fill}
          hover={this.hover}
          size={this.size}
          width={this.width}
          height={this.height}
        >
          {this.$createElement(this.name)}
        </StyledSvg>
      )
    }
  },
}
