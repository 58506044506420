export const state = () => ({
  popUpVisible: false,
  popUpName: null,
  popUpData: null,
  footerVisible: false,
  disableScroll: false
})

export const mutations = {
  setShowPopup(state, data) {
    state.popUpName = data
    state.popUpVisible = true
  },
  setHidePopup(state) {
    state.popUpName = null
    state.popUpData = null
    state.popUpVisible = false
  },
  setDisableScroll(state, value) {
    state.disableScroll = value
  }
}
