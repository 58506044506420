export const state = () => ({
  isLoading: true,
  documents: []
})

export const mutations = {
  setIsLoading(state, value) {
    state.isLoading = value
  },
  setDocuments(state, value) {
    state.documents = value
  }
}

export const actions = {
  async getDocuments({ rootState, commit }, productGroupParam) {
    commit('setIsLoading', true)
    const documents = await this.$axios.$get(
      `_/items/documents?fields=*.*&filter[language.culture]=${rootState.locale}&filter[product_group.slug]=${productGroupParam}&limit=5000`
    )
    const dataUnfiltered = documents.data.sort(sortByTitle)
    let dataFiltered = []
    if (dataUnfiltered && dataUnfiltered.length > 0) {
      dataFiltered = [...dataUnfiltered].reduce((d, current) => {
        const x = d.find((item) => item.link === current.link)
        if (!x) {
          return d.concat([current])
        } else {
          return d
        }
      }, [])
    }
    if (documents) commit('setDocuments', dataFiltered)
    commit('setIsLoading', false)
  }
}

function sortByTitle(a, b) {
  if (a.title < b.title) {
    return -1
  } else if (a.title > b.title) {
    return 1
  } else {
    return 0
  }
}
