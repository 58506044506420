import Vue from 'vue'
import VueAppInsights from 'vue-application-insights'

export default function(ctx, inject, envName, routes) {
  const instrumentationKey = '0669ba78-3c6d-48a0-bf99-2114e3df2f21'
  const _envName = process?.env?.envName || envName
  const enable = _envName === 'staging' || _envName === 'master'
  const _router = ctx?.app?.router

  if (enable) {
    Vue.use(VueAppInsights, {
      id: instrumentationKey,
      router: _router,
      baseName: 'TDH',
      appInsightsConfig: {
        instrumentationKey,
        enableAutoRouteTracking: true,
        disableTelemetry: false,
        disableExceptionTracking: false
      }
    })
  }
}
