export const state = () => ({
  locales: null,
  locale: 'en-GB',
  newLocale: 'en-GB',
  localeLabel: 'en',
  localeCountry: 'United Kingdom',
  localeFallback: 'en-GB',
  unknownLocale: false,
  lastRoute: '',
  translationValues: null,
  userAgent: ''
})

export const mutations = {
  setLocales(state, locales) {
    state.locales = locales
  },
  setLocale(state, locale) {
    state.locale = locale
  },
  setUserLocale(state) {
    const navCulture = navigator.language
    let culture = state.locales.find((l) => l.culture === navCulture)
    if (!culture) {
      culture = state.locales.find(
        (l) =>
          l.culture.toLowerCase() ===
          navCulture.substring(0, 2).toLowerCase() +
            '-' +
            navCulture.substring(0, 2).toLowerCase()
      )
    }
    if (!culture) {
      culture = state.locales.find(
        (l) => l.lang === navCulture.substring(0, 2).toLowerCase()
      )
    }
    if (!culture) {
      culture = state.localeFallback
    } else {
      culture = culture.culture
    }
    state.locale = culture
  },
  setNewLocale(state, locale) {
    state.newLocale = locale
  },
  setUnknownLocale(state, value) {
    state.unknownLocale = value
  },
  setLang(state, locale) {
    state.locales.forEach((myLocale) => {
      if (myLocale.culture === locale) {
        state.locale = locale
        state.localeLabel = myLocale.lang
        state.localeCountry = myLocale.country
      }
    })
  },
  checkLang(state, locale) {
    let errorFlag = true
    state.locales.forEach((myLocale) => {
      if (myLocale.culture === locale) {
        errorFlag = false
      }
    })
    if (errorFlag) {
      state.unknownLocale = true
    }
    return false
  },
  setTranslationValues(state, value) {
    state.translationValues = value
  },
  setLastRoute(state, value) {
    state.lastRoute = value
  },
  setUserAgent(state) {
    if (
      navigator.appName === 'Microsoft Internet Explorer' ||
      (navigator.appName === 'Netscape' &&
        new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})').exec(
          navigator.userAgent
        ) != null)
    ) {
      let rv = ''
      const ua = navigator.userAgent
      const re = new RegExp('Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})')
      if (re.exec(ua) != null) rv = parseFloat(RegExp.$1)
      state.userAgent = `user-agent-ie${rv}`
    }
  }
}

export const actions = {
  async getLanguages({ commit }) {
    const response = await this.$axios.$get(
      `_/items/languages?fields=*.*&filter[show]=true&sort=culture`
    )
    const data = response.data
    commit('setLocales', data)
    commit('setUserLocale')
  },
  async getTranslations(_, locale) {
    const response = await this.$axios.$get(
      `_/items/translation_values?fields=*.*&filter[language.culture][in]=${locale}&limit=5000`
    )
    const data = response.data.reduce(function(obj, item) {
      const keyword = item.translation_keyword
        ? item.translation_keyword.keyword
        : null
      obj[keyword] = item.translation
      return obj
    }, {})
    return data
  },
  async setTranslations({ state, commit, dispatch }, { locale, i18n }) {
    if (!state.locales) {
      await dispatch('getLanguages')
    }
    commit('checkLang', locale)
    if (state.unknownLocale) {
      commit('models/setIsLoadingTranslations', true, { root: true })
      return
    }
    if (!state.translationValues || state.newLocale !== state.locale) {
      const data = await dispatch('getTranslations', locale)
      commit('setTranslationValues', data)
    }
    commit('setLang', locale)
    i18n.setLocaleMessage(locale, state.translationValues)
    i18n.locale = locale
    commit('models/setIsLoadingTranslations', false, { root: true })
  }
}
