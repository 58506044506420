export const state = () => ({})

export const mutations = {}

export const actions = {
  async getExternalLink(_, name) {
    const externalLink = await this.$axios.$get(
      `_/items/external_links?fields=*.*&filter[show_url]=true&filter[name]=${name}`
    )
    if (externalLink && externalLink.data && externalLink.data.length > 0) {
      return externalLink.data[0].url
        ? externalLink.data[0].url
        : externalLink.data[0]
    }
    return null
  }
}
