import styled from 'vue-styled-components'

// use <flex-child-span :grow="'0'" :shrink="'0'" :basis="'0px'"></flex-child-span>

const componentProps = {
  grow: {
    type: String,
    default: '0'
  },
  shrink: {
    type: String,
    default: '0'
  },
  basis: {
    type: String,
    default: '0px'
  }
}

const FlexChildSpan = styled('span', componentProps)`
  flex: ${(props) => props.grow} ${(props) => props.shrink}
    ${(props) => props.basis};
`

export default {
  props: {
    grow: String,
    shrink: String,
    basis: String
  },
  render(h) {
    return (
      <FlexChildSpan
        class="flex-child"
        grow={this.grow}
        shrink={this.shrink}
        basis={this.basis}
        position={this.position}
      >
        {this.$slots.default}
      </FlexChildSpan>
    )
  }
}
