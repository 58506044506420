export const state = () => ({
  token: null
})

export const mutations = {
  setToken(state, value) {
    state.token = value
  }
}

export const actions = {
  setToken({ commit, rootState }, { token }) {
    if (!process.server) {
      window.localStorage.setItem('token', token)
    }
    commit('setToken', token)
  },
  clearToken({ commit, rootState }) {
    if (!process.server) {
      window.localStorage.removeItem('token')
    }
    commit('setToken', null)
  }
}

export const getters = {
  isAuthenticated(state) {
    return !!state.token
  }
}
