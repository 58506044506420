import styled from 'vue-styled-components'

// use <absolute :top="'0px'" :bottom="'0px'" :left="'0px'" :right="'0px'"></absolute>

const componentProps = {
  top: {
    type: String,
    default: 'auto'
  },
  bottom: {
    type: String,
    default: 'auto'
  },
  left: {
    type: String,
    default: 'auto'
  },
  right: {
    type: String,
    default: 'auto'
  }
}

const AbsoluteDiv = styled('div', componentProps)`
  position: absolute;
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
`

export default {
  props: {
    top: String,
    bottom: String,
    left: String,
    right: String
  },
  render(h) {
    return (
      <AbsoluteDiv
        top={this.top}
        bottom={this.bottom}
        left={this.left}
        right={this.right}
      >
        {this.$slots.default}
      </AbsoluteDiv>
    )
  }
}
