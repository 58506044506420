export default function({ isHMR, app, store, params }) {
  // If middleware is called from hot module replacement, ignore it
  if (isHMR) return

  // Get locale from params
  const locale = params.lang || store.state.locale

  // Set locale
  app.i18n.locale = locale
  app.i18n.fallbackLocale = store.state.localeFallback

  // Set translations from Directus
  if (!store.state.translationValues) {
    return store.dispatch('setTranslations', {
      locale,
      i18n: app.i18n
    })
  }
}
