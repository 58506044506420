export default function({ store, route, redirect }) {
  if (route.path === '/') {
    return store.dispatch('getLanguages')
  }
  let lang = route.params.lang
  let productGroup = route.params.productgroup
  const subject = route.params.subject
  const type = route.params.type
  if (lang === 'en' || productGroup === 'altherma-3') {
    if (lang === 'en') {
      lang = 'en-GB'
    }
    if (productGroup === 'altherma-3') {
      productGroup = 'altherma-3-h-ht'
    }
    if (route.name.includes('combination')) {
      return redirect(`/${lang}/${productGroup}/combination`)
    }
    if (route.name.includes('literature')) {
      return redirect(`/${lang}/${productGroup}/literature`)
    }
    if (subject && type) {
      return redirect(
        `/${lang}/${productGroup}/${type}/${subject}${
          route.query.id ? `?id=${route.query.id}` : ''
        }`
      )
    }
    if (type) {
      return redirect(`/${lang}/${productGroup}/${type}/`)
    }
    return redirect(`/${lang}/${productGroup}/`)
  }
  if (subject && type) {
    if (subject === 'undefined' || subject === 'undefined&layout=detail') {
      return redirect(`/${lang}/${productGroup}/${type}/`)
    }
  }
}
