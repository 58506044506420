export default function({ isServer, store, req }) {
  if (!store.getters['auth/isAuthenticated']) {
    let token

    if (!process.server) {
      token = window.localStorage.getItem('token')
    }

    if (token) {
      store.commit('auth/setToken', token)
    }
  }
}
