import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _bb3d2756 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a9d2795a = () => interopDefault(import('../pages/_lang/index.vue' /* webpackChunkName: "pages/_lang/index" */))
const _4e3f1653 = () => interopDefault(import('../pages/_lang/_productgroup/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/index" */))
const _25638993 = () => interopDefault(import('../pages/_lang/_productgroup/combination/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/combination/index" */))
const _07f7bba3 = () => interopDefault(import('../pages/_lang/_productgroup/literature/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/literature/index" */))
const _0791451d = () => interopDefault(import('../pages/_lang/_productgroup/_type/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/_type/index" */))
const _3b5ad575 = () => interopDefault(import('../pages/_lang/_productgroup/_type/_subject/index.vue' /* webpackChunkName: "pages/_lang/_productgroup/_type/_subject/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/",
    component: _bb3d2756,
    name: "index"
  }, {
    path: "/:lang",
    component: _a9d2795a,
    name: "lang"
  }, {
    path: "/:lang/:productgroup",
    component: _4e3f1653,
    name: "lang-productgroup"
  }, {
    path: "/:lang/:productgroup/combination",
    component: _25638993,
    name: "lang-productgroup-combination"
  }, {
    path: "/:lang/:productgroup/literature",
    component: _07f7bba3,
    name: "lang-productgroup-literature"
  }, {
    path: "/:lang/:productgroup/:type",
    component: _0791451d,
    name: "lang-productgroup-type"
  }, {
    path: "/:lang/:productgroup/:type/:subject",
    component: _3b5ad575,
    name: "lang-productgroup-type-subject"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
