import styled from 'vue-styled-components'
import size from '@/components/kit/size'

// use <padding all="20" top="15" right="15" bottom="15" left="15"></padding>

const componentProps = {
  all: String,
  top: String,
  right: String,
  bottom: String,
  left: String
}

const PaddingDiv = styled('div', componentProps)`
  padding-top: ${(props) => size.knownSizeProp('top', props)}px;
  padding-right: ${(props) => size.knownSizeProp('right', props)}px;
  padding-bottom: ${(props) => size.knownSizeProp('bottom', props)}px;
  padding-left: ${(props) => size.knownSizeProp('left', props)}px;
`

export default {
  props: {
    all: String,
    top: String,
    right: String,
    bottom: String,
    left: String
  },
  render() {
    return (
      <PaddingDiv
        class="padding"
        all={this.all}
        top={this.top}
        right={this.right}
        bottom={this.bottom}
        left={this.left}
      >
        {this.$slots.default}
      </PaddingDiv>
    )
  }
}
