export const state = () => ({
  highlights: []
})

export const mutations = {
  setHighlights(state, value) {
    if (value) {
      const mySorter = function(a, b) {
        const x = a.translation_keyword.keyword.toLowerCase()
        const y = b.translation_keyword.keyword.toLowerCase()
        return x < y ? -1 : x > y ? 1 : 0
      }
      const highlights = value.sort(mySorter)
      state.highlights = highlights
    } else {
      state.highlights = []
    }
  }
}

export const actions = {
  async getHighlights({ rootState, commit }, { typeName, indoorTypeFilter }) {
    const highlights = await this.$axios.$get(
      `_/items/image_popover?fields=*.*&filter[type.name]=${typeName}${indoorTypeFilter}&filter[product_group.slug]=${rootState.models.selectedProductGroup.slug}`
    )
    if (highlights) commit('setHighlights', highlights.data)
  }
}
