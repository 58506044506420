export const state = () => ({
  powerVList: [],
  powerKwList: [],
  phaseList: [],
  selectedSolarCompatible: null,
  solarCompatibleList: [],
  modeList: [],
  selectedType: null,
  typeList: [],
  selectedColor: null,
  colorList: [],
  selectedIndoorType: null,
  indoorTypeList: [],
  hasDhw: false,
  selectedZone: null,
  applicationSchemeZoneList: [],
  zoneList: [],
  functionList: [],
  selectedEmitters: [],
  emitterList: [],
  filterOptions: {},
  filterOptionsCount: {}
})

export const mutations = {
  resetFilters(state, productGroupSlug) {
    const powerVList = [
      {
        id: 'power-3V',
        name: '3',
        _title: ['3V'],
        _subtitle: '3V',
        checked: false
      },
      {
        id: 'power-6V',
        name: '6',
        _title: ['6V - 6kW', 'oneThreePhaseBackupHeaterClassSubtitle'],
        _subtitle: '6V',
        checked: false
      },
      {
        id: 'power-9W',
        name: '9',
        _title: ['9W - 9kW', 'threePhaseSubtitle'],
        _subtitle: '9W',
        checked: false
      }
    ]
    state.powerVList = powerVList

    let powerKwList = [
      { id: 'power-4kw', title: '4 kW', name: '4', checked: false },
      { id: 'power-6kw', title: '6 kW', name: '6', checked: false },
      { id: 'power-8kw', title: '8 kW', name: '8', checked: false },
      { id: 'power-9kw', title: '9 kW', name: '9', checked: false },
      { id: 'power-10kw', title: '10 kW', name: '10', checked: false },
      { id: 'power-11kw', title: '11 kW', name: '11', checked: false },
      { id: 'power-12kw', title: '12 kW', name: '12', checked: false },
      { id: 'power-14kw', title: '14 kW', name: '14', checked: false },
      { id: 'power-16kw', title: '16 kW', name: '16', checked: false },
      { id: 'power-18kw', title: '18 kW', name: '18', checked: false }
    ]
    if (productGroupSlug && productGroupSlug === 'altherma-3-h-ht') {
      powerKwList = []
      powerKwList.push(
        { id: 'power-8kw', title: '8 kW', name: '8', checked: false },
        { id: 'power-10kw', title: '10 kW', name: '10', checked: false },
        { id: 'power-12kw', title: '12 kW', name: '12', checked: false },
        { id: 'power-14kw', title: '14 kW', name: '14', checked: false },
        { id: 'power-16kw', title: '16 kW', name: '16', checked: false },
        { id: 'power-18kw', title: '18 kW', name: '18', checked: false }
      )
    }
    if (productGroupSlug && productGroupSlug === 'altherma-3-r') {
      powerKwList = []
      powerKwList.push(
        { id: 'power-4kw', title: '4 kW', name: '4', checked: false },
        { id: 'power-6kw', title: '6 kW', name: '6', checked: false },
        { id: 'power-8kw', title: '8 kW', name: '8', checked: false }
      )
    }
    if (productGroupSlug && productGroupSlug === 'altherma-3-m') {
      powerKwList = []
      powerKwList.push(
        { id: 'power-9kw', title: '9 kW', name: '9', checked: false },
        { id: 'power-11kw', title: '11 kW', name: '11', checked: false },
        { id: 'power-14kw', title: '14 kW', name: '14', checked: false },
        { id: 'power-16kw', title: '16 kW', name: '16', checked: false }
      )
    }
    if (productGroupSlug && productGroupSlug === 'altherma-3-r-erla') {
      powerKwList = []
      powerKwList.push(
        { id: 'power-11kw', title: '11 kW', name: '11', checked: false },
        { id: 'power-14kw', title: '14 kW', name: '14', checked: false },
        { id: 'power-16kw', title: '16 kW', name: '16', checked: false }
      )
    }
    if (productGroupSlug && productGroupSlug === 'altherma-3-r-mt') {
      powerKwList = []
      powerKwList.push(
        { id: 'power-8kw', title: '8 kW', name: '8', checked: false },
        { id: 'power-10kw', title: '10 kW', name: '10', checked: false },
        { id: 'power-12kw', title: '12 kW', name: '12', checked: false }
      )
    }
    state.powerKwList = powerKwList

    state.phaseList = [
      { id: 'phase-1', name: 1, checked: false },
      { id: 'phase-3', name: 3, checked: false }
    ]
    state.selectedSolarCompatible = null
    state.solarCompatibleList = [
      { id: 'no-solar', i18n: 'noSolar', disabled: false },
      { id: 'pressurised', i18n: 'pressurised', disabled: false },
      { id: 'drainback', i18n: 'drainback', disabled: false }
    ]
    state.modeList = [
      {
        id: 'reversible',
        title: 'reversible',
        name: 'reversible',
        checked: false
      },
      {
        id: 'heating-only',
        title: 'heatingOnly',
        name: 'heatingOnly',
        checked: false
      }
    ]
    state.selectedType = null
    state.typeList = [
      { id: 'standard', title: 'standard', name: 'standard', checked: false },
      { id: 'bivalent', title: 'bivalent', name: 'bivalent', checked: false }
    ]
    state.selectedColor = null
    state.colorList = [
      { id: 'white', i18n: 'white', disabled: false },
      { id: 'grey', i18n: 'grey', disabled: false }
    ]
    state.hasDhw = false
    state.selectedZone = null
    state.applicationSchemeZoneList = [
      { id: '1-zone', i18n: 'oneZone', disabled: false },
      { id: '2-zone', i18n: 'twoZones', disabled: false }
    ]
    state.zoneList = [
      { id: '1-zone', title: 'oneZone', name: 'oneZone', checked: false },
      { id: '2-zone', title: 'twoZones', name: 'twoZones', checked: false }
    ]
    state.functionList = [
      { id: 'func-heating', name: 'heating', checked: true },
      { id: 'func-cooling', name: 'cooling', checked: true }
    ]
    state.selectedEmitters = []

    const emitterList = [
      { id: 'emitter-ufh', name: 'ufh', checked: true, disabled: false },
      { id: 'emitter-fcu', name: 'fcu', checked: false, disabled: false }
    ]
    if (productGroupSlug && productGroupSlug === 'altherma-3-h-ht') {
      emitterList.unshift({
        id: 'emitter-radiators',
        name: 'radiators',
        checked: false,
        disabled: false
      })
    }
    state.emitterList = emitterList
  },
  setPowerVList(state, value) {
    const list = [...state.powerVList]
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.id === value.id) {
        item.checked = value.checked
        break
      }
    }
    state.powerVList = list
  },
  setPowerKwList(state, value) {
    const list = [...state.powerKwList]
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.id === value.id) {
        item.checked = value.checked
        break
      }
    }
    state.powerKwList = list
  },
  setPhaseList(state, value) {
    const list = [...state.phaseList]
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.id === value.id) {
        item.checked = value.checked
        break
      }
    }
    state.phaseList = list
  },
  setZoneList(state, value) {
    const list = [...state.zoneList]
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.id === value.id) {
        item.checked = value.checked
        break
      }
    }
    state.zoneList = list
  },
  setTypeList(state, value) {
    const list = [...state.typeList]
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.id === value.id) {
        item.checked = value.checked
        break
      }
    }
    state.typeList = list
  },
  setModeList(state, value) {
    const list = [...state.modeList]
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.id === value.id) {
        item.checked = value.checked
        break
      }
    }
    state.modeList = list
  },
  setColorList(state, value) {
    state.colorList = value
  },
  setFunctionList(state, value) {
    const list = [...state.functionList]
    for (let index = 0; index < list.length; index++) {
      const item = list[index]
      if (item.id === value.id) {
        item.checked = value.checked
        break
      }
    }
    state.functionList = list
  },
  setFirstSelectedEmitter(state, value) {
    const list = [...state.emitterList]
    if (value) {
      for (let index = 0; index < list.length; index++) {
        const item = list[index]
        if (item.id === value.id) {
          item.checked = value.checked
          break
        }
      }
    } else {
      state.firstSelectedEmitter = list.filter(
        (item) => item.checked === true
      )[0]
    }
  },
  setEmitterList(state, { value, restriction }) {
    const list = [...state.emitterList]
    if (value) {
      switch (restriction) {
        case 1:
          for (let index = 0; index < list.length; index++) {
            const item = list[index]
            if (item.id === value.id) {
              item.checked = value.checked
            } else {
              item.checked = false
            }
          }
          break

        case 2:
          if (state.selectedEmitters.length === 0) {
            for (let index = 0; index < list.length; index++) {
              const item = list[index]
              if (item.checked) {
                state.selectedEmitters.push(item)
              }
            }
          }
          if (value.checked) {
            while (state.selectedEmitters.length >= restriction) {
              state.selectedEmitters.shift()
            }
            state.selectedEmitters.push(value)
          } else {
            state.selectedEmitters = state.selectedEmitters.filter(
              (item) => item.id !== value.id
            )
          }
          for (let index = 0; index < list.length; index++) {
            const listItem = list[index]
            const test = state.selectedEmitters.filter(
              (item) => item.id === listItem.id
            )
            list[index].checked = test.length > 0
          }
          break

        default:
          break
      }
    } else if (state.selectedEmitters.length > 1) {
      state.selectedEmitters.shift()
      for (let index = 0; index < list.length; index++) {
        const listItem = list[index]
        const test = state.selectedEmitters.filter(
          (item) => item.id === listItem.id
        )
        list[index].checked = test.length > 0
      }
    }
    state.emitterList = list
  },
  setSelectedSolarCompatible(state, value) {
    state.selectedSolarCompatible = value
  },
  setHasDhw(state, value) {
    state.hasDhw = value
  },
  setSelectedType(state, value) {
    state.selectedType = value && value.checked ? value : null
  },
  setSelectedZone(state, value) {
    state.selectedZone = value
  },
  setSelectedIndoorType(state, value) {
    state.selectedIndoorType = value
  },
  setSelectedColor(state, value) {
    state.selectedColor = value
  },
  setIndoorTypeList(state, value) {
    state.indoorTypeList = value
  },
  setFilterOptions(state, filters) {
    state.filterOptions = filters
  },
  resetFilterOptionsCount(state) {
    state.filterOptionsCount = {}
  },
  setFilterOptionsCount(state, filterOptionsCount) {
    filterOptionsCount.forEach((filters) => {
      if (state.filterOptionsCount[filters.prop]) {
        const hasFound = state.filterOptionsCount[filters.prop].find(
          (f) => f.prop === filters.value
        )
        if (hasFound) {
          state.filterOptionsCount[filters.prop].map((f) => {
            if (f.prop === filters.value) {
              f.value++
            }
          })
        } else {
          state.filterOptionsCount[filters.prop].push({
            prop: filters.value,
            value: 1
          })
        }
      } else {
        state.filterOptionsCount[filters.prop] = []
        state.filterOptionsCount[filters.prop].push({
          prop: filters.value,
          value: 1
        })
      }
    })
  }
}

export const actions = {
  initFilterOptions({ rootState, commit }) {
    const filters = {}
    if (rootState.models.outdoorType) {
      filters.power = rootState.models.availableModels.some((m) => m.power)
      filters.phase = rootState.models.availableModels.some((m) => m.phase)
      filters.mode = rootState.models.availableModels.some((m) => m.mode)
    }
    if (!rootState.models.outdoorType && rootState.models.indoorType) {
      filters.mode = rootState.models.availableModels.some((m) => m.mode)

      if (rootState.models.indoorTypeName !== 'ech2o') {
        filters.power = rootState.models.availableModels.some((m) => m.power)
      }
      if (rootState.models.indoorTypeName === 'ech2o') {
        filters.bivalent = rootState.models.availableModels.some(
          (m) => m.bivalent
        )
      }
      if (rootState.models.indoorTypeName === 'floorstanding') {
        filters.zone = rootState.models.availableModels.some((m) => m.zone)
        filters.color = rootState.models.availableModels.some((m) => m.color)
      }
    }
    commit('setFilterOptions', filters)
  },
  filterModels({ state, rootState, commit }) {
    commit('resetFilterOptionsCount')
    const models = []
    const filterOptionsCount = []
    if (rootState.models.availableModels.length > 0) {
      const availableModels = [...rootState.models.availableModels]
      for (let index = 0; index < availableModels.length; index++) {
        const model = availableModels[index]
        if (state.filterOptions.power && rootState.models.outdoorType) {
          const isFilteringPowerKw = state.powerKwList.find(
            (power) => power.checked
          )
          const checkPowerKw = state.powerKwList.find((power) => {
            const check = power.name === model.power
            if (check) {
              filterOptionsCount.push({
                prop: 'power',
                value: power.name
              })
            }
            return check && power.checked
          })
          if (isFilteringPowerKw && !checkPowerKw) {
            continue
          }
        }
        if (state.filterOptions.power) {
          const isFilteringPowerV = state.powerVList.find(
            (power) => power.checked
          )
          const checkPowerV = state.powerVList.find((power) => {
            const check = power.name === model.power
            if (check) {
              filterOptionsCount.push({
                prop: 'power',
                value: power.name
              })
            }
            return check && power.checked
          })
          if (isFilteringPowerV && !checkPowerV) {
            continue
          }
        }
        if (state.filterOptions.phase) {
          const isFilteringPhase = state.phaseList.find(
            (phase) => phase.checked
          )
          const checkPhase = state.phaseList.find((phase) => {
            const check = model.phase ? phase.name === model.phase.value : false
            if (check) {
              filterOptionsCount.push({
                prop: 'phase',
                value: phase.name
              })
            }
            return check && phase.checked
          })
          if (isFilteringPhase && !checkPhase) {
            continue
          }
        }
        if (state.filterOptions.mode) {
          const isFilteringMode = state.modeList.find((mode) => mode.checked)
          const checkMode = state.modeList.find((mode) => {
            const check = model.mode ? mode.name === model.mode : false
            if (check) {
              filterOptionsCount.push({
                prop: 'mode',
                value: mode.name
              })
            }
            return check && mode.checked
          })
          if (isFilteringMode && !checkMode) {
            continue
          }
        }
        if (state.filterOptions.color) {
          if (state.selectedColor && state.selectedColor.i18n !== model.color) {
            continue
          }
        }
        if (state.filterOptions.zone) {
          const isFilteringZone = state.zoneList.find((zone) => zone.checked)
          const checkZone = state.zoneList.find((zone) => {
            const check = model.zone ? zone.name === model.zone : false
            if (check) {
              filterOptionsCount.push({
                prop: 'zone',
                value: zone.name
              })
            }
            return check && zone.checked
          })
          if (isFilteringZone && !checkZone) {
            continue
          }
        }
        if (state.filterOptions.bivalent) {
          const isFilteringType = state.typeList.find((type) => type.checked)
          const checkType = state.typeList.find((type) => {
            const check = type.name === model.bivalent
            if (check) {
              filterOptionsCount.push({
                prop: 'bivalent',
                value: type.name
              })
            }
            return type.name === model.bivalent && type.checked
          })
          if (isFilteringType && !checkType) {
            continue
          }
        }
        models.push(model)
      }
    }
    commit('models/setAvailableFilteredModels', models, { root: true })
    commit('setFilterOptionsCount', filterOptionsCount)
  },
  filterTanks({ state, commit, rootState }) {
    const models = []
    if (rootState.models.tankModels.length > 0) {
      const tankModels = [...rootState.models.tankModels]
      for (let index = 0; index < tankModels.length; index++) {
        const model = tankModels[index]
        if (
          state.selectedSolarCompatible &&
          state.selectedSolarCompatible.i18n !== model.solar_compatible
        ) {
          continue
        }
        models.push(model)
      }
      commit('models/setFilteredTankModels', models, { root: true })
    }
  }
}
