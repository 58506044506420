const opposites = {
  top: 'bottom',
  left: 'right',
  right: 'left',
  bottom: 'top'
}

export default {
  'space-xxs': '5',
  'space-xs': '10',
  'space-s': '15',
  'space-m': '20',
  'space-l': '30',
  'space-xl': '40',
  'space-xxl': '60',

  knownSize(value) {
    if (this[value]) {
      return this[value]
    }
    return value
  },
  knownSizeProp(side, props) {
    if (props.all) {
      return this.knownSize(props.all)
    } else {
      const sideValue = props[side]
      const oppositeValue = props[opposites[side]]
      if (sideValue || sideValue === '0') {
        return this.knownSize(sideValue)
      } else if (sideValue == null && oppositeValue == null) {
        return this.knownSize('0')
      } else {
        return this.knownSize(oppositeValue)
      }
    }
  }
}
