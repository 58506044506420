import styled from 'vue-styled-components'
import colors from '@/components/kit/theme'

// use <button-color :background="'white'" :background-opacity="'30'" :border="'black'" :text="'black'"></color>

const componentProps = {
  background: String,
  backgroundOpacity: Number,
  border: String,
  borderRadius: String,
  text: String
}

const ButtonColorDiv = styled('div', componentProps)`
  &.shadow:not(.disabled) {
    &:hover,
    &:focus {
      box-shadow: 0 5px 10px 0
        ${(props) => colors.knownColorWithOpacity(props.border, 20)};
      border-color: ${(props) =>
        colors.knownColorWithOpacity(props.border, 40)} !important;
    }
  }
`

export default {
  props: {
    background: String,
    backgroundOpacity: Number,
    border: String,
    borderRadius: String,
    text: String
  },
  render(h) {
    return (
      <ButtonColorDiv
        background={this.background}
        background-opacity={this.backgroundOpacity}
        border={this.border}
        border-radius={this.borderRadius}
        text={this.text}
        class="btn"
      >
        {this.$slots.default}
      </ButtonColorDiv>
    )
  }
}
