export const state = () => ({
  hasCombination: false,
  combinationModels: []
})

export const mutations = {
  setHasCombination(state, value) {
    state.hasCombination = value
  },
  setCombinationModels(state, value) {
    state.combinationModels = value
  }
}

export const actions = {
  async getCombinations({ commit }, { modelType, combinationType, modelId }) {
    const combinations = await this.$axios.$get(
      `_/items/combinations?filter[${modelType}_model_id]=${modelId}`
    )
    let models = []
    if (combinations.data && combinations.data.length > 0) {
      models = combinations.data.map((c) => c[`${combinationType}_model_id`])
    }
    commit('setHasCombination', models.length > 0)
    commit('setCombinationModels', models)
  }
}
