export default (ctx, inject) => {
  ctx.$gtm.init(ctx.env.gtmId)

  inject('getEvent', (category, extra) => {
    if (!category) {
      return
    }
    switch (category) {
      case 'combine':
        return {
          event: 'Click on button',
          label: `Combined with this unit - ${extra.type.name}${
            extra.indoor_type ? ` - ${extra.indoor_type.name}` : ''
          } - ${extra.name}`,
          action: 'click'
        }
      case 'filter':
        return {
          event: 'Click on filter',
          label: `Filtering available models - ${extra.type} - ${extra.value.id}`,
          action: 'click'
        }

      case 'navigation':
        return { event: 'Click on navigation', path: extra, action: 'click' }

      case 'subnavigation':
        return {
          event: 'Click on product subnavigation',
          label: extra,
          action: 'click'
        }
    }
  })

  inject('gtmSendEvent', (category, extra) => {
    const myEvent = {
      category
    }
    const result = ctx.app.$getEvent(category, extra)
    myEvent.event = result.event
    if (result.label) {
      myEvent.label = result.label
    }
    if (result.path) {
      myEvent.path = result.path
    }
    myEvent.action = result.action
    myEvent.lang = ctx.app.i18n.locale
    console.log('gtmSendEvent', myEvent)
    ctx.$gtm.push(myEvent)
  })
}
