export const state = () => ({
  isLoading: false,
  lwt: 55,
  lwtDefault: 55,
  lwtDefaultCooling: 22,
  lwtSteps: 5,
  lwtStepsCooling: [7, 10, 13, 15, 18, 22],
  lwtMin: 25,
  lwtMinCooling: 7,
  lwtMax: 70,
  lwtMaxCooling: 22,
  capacityTable: null,
  indoorFilter: null,
  hasPowerInput: false,
  isCooling: false,
  capacityTableAllValues: null,
  labels: [],
  options: null,
  mode: null,
  originalModes: [
    { id: 1, i18n: 'heating', _mode: 'heating' },
    { id: 2, i18n: 'heatingPumpOnly', _mode: 'heating' },
    { id: 3, i18n: 'heatingPumpOnlyGlycol', _mode: 'heating' },
    { id: 4, i18n: 'heatingPumpOnlyWater', _mode: 'heatingWater' },
    { id: 5, i18n: 'heatingLowSound', _mode: 'heatingLowSound' },
    { id: 6, i18n: 'heatingLowSoundPumpOnly', _mode: 'heatingLowSound' },
    { id: 7, i18n: 'heatingPeak', _mode: 'heatingPeak' },
    { id: 8, i18n: 'cooling', _mode: 'cooling' },
    { id: 9, i18n: 'coolingLowSound', _mode: 'coolingLowSound' }
  ],
  modes: null
})

export const mutations = {
  resetCapacityTable(state) {
    state.capacityTable = null
  },
  setIsLoading(state, value) {
    state.isLoading = value
  },
  setCustomTooltip(state, value) {
    state.options.tooltips.custom = value
  },
  setOptions(state, value) {
    state.options = value
  },
  setMode(state, value) {
    state.hasPowerInput = false
    state.mode = value
    const filteredModes = []
    if (value) {
      state.indoorFilter.modes.filter((item) => {
        filteredModes.push(item.split('_'))
      })
      filteredModes.forEach((mode) => {
        if (mode[0] === value?.i18n) {
          if (mode[1] === 'power') {
            state.hasPowerInput = true
          }
        }
      })

      if (value?._mode.includes('cooling')) {
        state.isCooling = true
      } else {
        state.isCooling = false
      }
    }
  },
  setModes(state) {
    if (state.indoorFilter) {
      const newArray = []
      state.indoorFilter.modes.forEach((mode, i) => {
        const filteredMode = state.originalModes.filter((item) => {
          return item.i18n === mode.split('_', 1)[0]
        })[0]
        if (!newArray.includes(filteredMode)) {
          if (filteredMode) {
            newArray.push(filteredMode)
          }
        }
      })
      const filteredNewArray = newArray.filter((item) => item !== undefined)
      state.modes = filteredNewArray
    }
  },
  setLabels(state, value) {
    state.labels = value
  },
  setIndoorFilter(state, value) {
    state.indoorFilter = value
  },
  setCapacityTableAllValues(state, value) {
    state.capacityTableAllValues = value
  },
  setCapacityTable(state, { value, titles }) {
    let chartData = null
    if (value) {
      const heatingCapacityColor = '#32B3E8'
      const powerInputColor = '#E8324D'

      const hc = []
      value.heating.forEach((heatingVal) => {
        if (heatingVal === 0) {
          hc.push(null)
        } else {
          let extraCapacity = 0
          const modeWithExtraCapacity =
            state.mode.i18n === 'heating' ||
            state.mode.i18n === 'heatingLowSound'
          if (
            state.indoorFilter &&
            state.indoorFilter.extra_capacity &&
            modeWithExtraCapacity
          ) {
            extraCapacity = parseInt(state.indoorFilter.extra_capacity)
          }
          hc.push((heatingVal + extraCapacity).toFixed(2))
        }
      })

      const pw = []
      value.power.forEach((powerVal) => {
        if (powerVal === 0) {
          pw.push(null)
        } else {
          pw.push(powerVal)
        }
      })

      const heatingCapacity = {
        label: titles[0],
        lineTension: 0,
        backgroundColor: 'transparent',
        borderColor: heatingCapacityColor,
        borderWidth: 2,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverRadius: 6,
        pointHoverBorderColor: heatingCapacityColor,
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderWidth: 2,
        data: hc
      }
      const powerInput = {
        label: titles[1],
        lineTension: 0,
        backgroundColor: 'transparent',
        borderColor: powerInputColor,
        borderWidth: 2,
        pointBorderColor: 'transparent',
        pointBackgroundColor: 'transparent',
        pointHoverRadius: 6,
        pointHoverBorderColor: powerInputColor,
        pointHoverBackgroundColor: '#ffffff',
        pointHoverBorderWidth: 2,
        data: pw
      }
      const datasets = []
      datasets.push(heatingCapacity)
      if (state.hasPowerInput) {
        datasets.push(powerInput)
      }
      chartData = {
        labels: state.labels,
        datasets
      }
    }
    state.capacityTable = chartData
  },
  setLwt(state, value) {
    state.lwt = value
  },
  setLwtValues(state, value) {
    if (value) {
      state.lwtDefault = value.default_value
      state.lwtDefaultCooling = value.default_cooling_value
      state.lwtSteps = value.steps_default_value
        ? value.steps_default_value
        : value.steps_default_custom_values
      state.lwtStepsCooling = value.steps_cooling_value
        ? value.steps_cooling_value
        : value.steps_cooling_custom_values
      state.lwtMin = value.min_value
      state.lwtMinCooling = value.min_cooling_value
      state.lwtMax = value.max_value
      state.lwtMaxCooling = value.max_cooling_value
    }
  },
  setLwtMax(state, value) {
    state.lwtMax = value
  },
  setLwtMin(state, value) {
    state.lwtMin = value
  },
  setLwtMaxCooling(state, value) {
    state.lwtMaxCooling = value
  },
  setLwtMinCooling(state, value) {
    state.lwtMinCooling = value
  }
}

export const actions = {
  async getLwtValues({ state, commit }, productGroupParam) {
    const data = await this.$axios.$get(
      `_/items/capacity_table_lwt?fields=*.*&filter[product_group.slug]=${productGroupParam}`
    )
    if (data && data.data.length > 0) {
      commit('setLwtValues', data.data[0])
      commit('setLwt', data.data[0].default_value)
    }
  },
  checkLwt({ state, commit }) {
    if (state.isCooling) {
      commit('setLwt', state.lwtDefaultCooling)
    } else {
      commit('setLwt', state.lwtDefault)
    }
  },
  async getCapacityTableLabels({ state, rootState, commit }) {
    let labels = null
    const capacity = state.isCooling ? '-cooling' : ''
    const hasWater = state.mode?._mode === 'heatingWater' ? '-water' : ''
    labels = await this.$axios.$get(
      `_/items/chart_labels?fields=*.*&filter[name]=capacitytable${hasWater ||
        capacity}&filter[product_group.slug]=${
        rootState.models.selectedProductGroup?.slug
      }`
    )
    if (labels && labels.data.length > 0) {
      commit('setLabels', labels.data[0].labels)
    }
  },
  async getCapacityTableIndoorFilter(
    { dispatch, commit, state, rootState },
    hasIndoorUnit
  ) {
    commit('setIsLoading', true)
    const modelId =
      hasIndoorUnit && rootState.models && rootState.models.selectedIndoorUnit
        ? rootState.models.selectedIndoorUnit.id
        : rootState.models && rootState.models.selectedOutdoorUnit
        ? rootState.models.selectedOutdoorUnit.id
        : null
    if (modelId) {
      const indoorFilter = await this.$axios.$get(
        `_/items/capacity_table_indoor_filter?fields=*.*&filter[indoor_models.models_id]=${modelId}`
      )
      await commit('setIndoorFilter', indoorFilter.data[0])
      await commit('setModes')

      let modeIsAvailable = false
      for (let i = 0; i < state.modes.length; i++) {
        if (state.modes[i] === state.mode) {
          modeIsAvailable = true
        }
      }
      if (!state.mode || !modeIsAvailable) {
        commit('setMode', state.modes[0])
        await dispatch('checkLwt')
      }
    }
  },
  async getCapacityTable(
    { commit, state, rootState, dispatch },
    { options, customTooltips, unitChanged, hasIndoorUnit }
  ) {
    let capacityTable = state.capacityTable
    if (unitChanged) {
      commit('setIsLoading', true)
      await dispatch('getCapacityTableIndoorFilter', hasIndoorUnit)
      if (state.indoorFilter && rootState.models.selectedOutdoorUnit) {
        capacityTable = await this.$axios.$get(
          `_/items/capacity_table?fields=*.*&filter[outdoor.name]=${rootState.models.selectedOutdoorUnit.name}&filter[mode]=${state.mode._mode}`
        )
        if (capacityTable.data.length > 0) {
          if (state.mode._mode === 'heating') {
            commit(
              'setLwtMax',
              Math.max(...capacityTable.data.map((item) => item.lwt))
            )
            commit(
              'setLwtMin',
              Math.min(...capacityTable.data.map((item) => item.lwt))
            )
          } else {
            commit(
              'setLwtMaxCooling',
              Math.max(...capacityTable.data.map((item) => item.lwt))
            )
            commit(
              'setLwtMinCooling',
              Math.min(...capacityTable.data.map((item) => item.lwt))
            )
          }
          capacityTable = capacityTable.data
        } else {
          capacityTable = null
        }
      } else {
        capacityTable = null
      }
      commit('setCapacityTableAllValues', capacityTable)
    }
    const capacityTableAllValues = state.capacityTableAllValues
    if (capacityTableAllValues && capacityTableAllValues.length > 0) {
      const filteredTable = capacityTableAllValues.filter((data) => {
        if (data.lwt === state.lwt) {
          return data
        }
      })[0]
      const _options = JSON.parse(JSON.stringify(options))
      const hasWater = state.mode?._mode === 'heatingWater'
      _options.scales.xAxes[0].scaleLabel.labelString = hasWater
        ? this.app.i18n.t('capacityTableWaterXScaleLabel')
        : this.app.i18n.t('capacityTableXScaleLabel')
      await dispatch('getCapacityTableLabels')
      commit('setOptions', _options)
      commit('setCustomTooltip', customTooltips)
      commit('setCapacityTable', {
        value: filteredTable,
        titles: [
          state.isCooling
            ? rootState.translationValues.coolingCapacity
            : rootState.translationValues.heatingCapacity,
          rootState.translationValues.powerInput
        ]
      })
    } else {
      commit('resetCapacityTable')
    }
    commit('setIsLoading', false)
  }
}
