import Vue from 'vue'
import { Line, mixins } from 'vue-chartjs'
import chartannotation from 'chartjs-plugin-annotation'
const { reactiveProp } = mixins

Vue.component('line-chart', {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  watch: {
    options() {
      this.renderChart(this.chartData, this.options)
    }
  },
  mounted() {
    this.addPlugin(chartannotation)
    this.renderChart(this.chartData, this.options)
  }
})
