<template>
  <div :class="userAgent">
    <page-loader v-if="isLoadingTranslations" />
    <template v-else>
      <div class="container" :class="{ loading: isLoading }">
        <custom-header />
        <custom-navigation v-if="$route.name !== 'lang'" />
        <nuxt class="content" />
        <popups v-if="showingPopup" />
      </div>
      <cookie-policy-notification />
      <custom-footer v-if="!isLoading" />
    </template>
  </div>
</template>

<script>
export default {
  head() {
    return {
      title: this.title,
      htmlAttrs: {
        class: this.htmlClasses
      },
      meta: this.meta
    }
  },
  components: {
    CookiePolicyNotification: () =>
      import('~/components/shared/CookiePolicyNotification'),
    CustomFooter: () => import('~/components/shared/CustomFooter'),
    CustomHeader: () => import('~/components/shared/CustomHeader'),
    CustomNavigation: () => import('~/components/shared/CustomNavigation'),
    PageLoader: () => import('~/components/shared/PageLoader'),
    Popups: () => import('~/components/popups/Popups')
  },
  computed: {
    title() {
      return `${this.$t('daikin')} ${this.productGroupName} ${
        this.modelTypeKeyword
          ? this.$t(this.modelTypeKeyword)
          : this.$t('combination')
      } ${
        this.$route.params.subject ? this.$t(this.$route.params.subject) : ''
      }`
    },
    productGroup() {
      return this.$store.state.models.selectedProductGroup
    },
    productGroupName() {
      return this.productGroup ? this.productGroup.name : ''
    },
    modelTypeKeyword() {
      return this.$store.state.models.modelTypeKeyword
    },
    htmlClasses() {
      if (this.hasDisableScroll || this.showingPopup) {
        return 'disable-scroll'
      }
      return ''
    },
    showingPopup() {
      return this.$store.state.components.popUpVisible
    },
    hasDisableScroll() {
      return this.$store.state.components.disableScroll
    },
    isLoadingTranslations() {
      return this.$store.state.models.isLoadingTranslations
    },
    userAgent() {
      return this.$store.state.userAgent
    },
    isLoading() {
      return this.$store.state.models.isLoading
    },
    unknownLocale() {
      return this.$store.state.unknownLocale
    },
    meta() {
      if (process.env.envName !== 'master') {
        return [
          {
            name: 'robots',
            content: 'noindex'
          },
          {
            name: 'googlebot',
            content: 'noindex'
          }
        ]
      }
      return null
    }
  },
  watch: {
    $route() {
      this.checkCookiePolicyLocalStorage()
    }
  },
  fetch() {
    this.checkCulture()
    this.checkCookiePolicyLocalStorage()
  },
  methods: {
    checkCulture() {
      if (this.unknownLocale) {
        this.$router.push({
          name: 'lang',
          params: { lang: this.$store.state.localeFallback }
        })
        this.$store.commit('setUnknownLocale', false)
      }
    },
    checkCookiePolicyLocalStorage() {
      if (window.localStorage.getItem('cookies') === 'false') {
        this.$store.commit('cookies/setCookies', false)
      }
    }
  }
}
</script>

<style lang="less">
@import (reference) '~@/assets/less/main.less';

.container {
  padding-bottom: 460px;

  &.loading {
    min-height: 100vh;
  }
}

.content {
  position: relative;
}

.wrapper {
  .lh-flex(1 0 0px);
  margin: 0 auto;
  max-width: @screensize-lg;
  padding: 0 @space-xs;
}

.content > section {
  padding-bottom: @space-xl;
  margin-bottom: @space-xl;

  &:first-child,
  &.chooser + section,
  &.back + section {
    .wrapper {
      > * {
        &.title {
          padding: @space-xxxl 0 @space-xxl;

          > .subtitle {
            margin-top: @space-xs;
          }
        }

        &.title + .subtitle {
          margin-top: -@space-m;
        }

        &.subtitle {
          padding-bottom: @space-xxl;
        }
      }
    }
  }

  &.color {
    padding-bottom: @space-xxl;
    margin-bottom: @space-xl;

    &:not(.color) {
      .wrapper {
        padding-bottom: @space-xxl;
        border-bottom: 1px solid @grey-light;
      }
    }
  }

  + section {
    padding-bottom: 0;

    &:not(.color):not(:last-child) {
      .wrapper {
        padding-bottom: @space-xl;
        border-bottom: 1px solid @grey-light;
      }
    }
  }
}
</style>
